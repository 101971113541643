/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CtrlNavbar from "./navbar"
import "bootstrap/dist/css/bootstrap.min.css"
import "@css/index.css"
import Footer from "./footer"

const Layout = ({ children }: any): JSX.Element => {
  return (
    <>
      <CtrlNavbar />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
