import React from "react"
import FontAwesome from 'react-fontawesome'
import SelectLanguage from "./select-language"
import FooterForm from "./footer-form"
import { useIntl, Link } from "gatsby-plugin-intl"
import { Container, Row, Col } from "react-bootstrap"

const Footer = (): JSX.Element => {
  const intl = useIntl()
  return (
    <footer>
      <Row className="footer" noGutters>
        <Container>
          <Row>
            <Col lg={6} xs={12} className="order-lg-1 order-2">
              <img
                className="mobile-img mt-lg-0 mt-5"
                src="/images/appscreen.png"
              />
            </Col>
            <Col lg={6} xs={12} className="order-lg-2 order-1">
              <Row className="list-title" noGutters>
                {intl.formatMessage({ id: 'footer.notificationOnScore' })}{" "}
                <span className="green-text">{intl.formatMessage({ id: 'footer.credibility' })}</span>
              </Row>
              <Row className="right-column" noGutters>
                <img
                  src="/images/check-icon.svg"
                  className="circle-check-icon"
                />
                {intl.formatMessage({ id: 'footer.notificationWeb' })}
              </Row>
              <Row className="right-column" noGutters>
                <img
                  src="/images/check-icon.svg"
                  className="circle-check-icon"
                />
                {intl.formatMessage({ id: 'footer.notificationMobile' })}

              </Row>
              <Row className="right-column" noGutters>
                <img
                  src="/images/check-icon.svg"
                  className="circle-check-icon"
                />
                {intl.formatMessage({ id: 'footer.subscribeAgency' })}

              </Row>
              <Row className="right-column" noGutters>
                <img
                  src="/images/check-icon.svg"
                  className="circle-check-icon"
                />
                {intl.formatMessage({ id: 'footer.availableOn' })}
              </Row>
              <Row
                className="mt-5 justify-content-lg-start justify-content-center"
                noGutters
              >
                <a
                  href="https://play.google.com/store/apps/details?id=md.mediacritica.mediaradar"
                  target="_blank"
                  rel="noreferrer"
                  className="mr-3 google-img"
                >
                  <img
                    src="/images/playstore.jpg"
                    alt=""
                  />
                </a>
                <a
                  href="https://apps.apple.com/app/id1526637882"
                  target="_blank"
                  rel="noreferrer"
                  className="apple-img"
                >

                  <img src="/images/appstore.jpg" alt="" />
                </a>
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="subfooter" noGutters>
        <Container>
          <Row className="pb-5 mb-lg-4 mb-0">
            <Col
              lg={3}
              md={6}
              xs={12}
              className="mb-lg-0 mb-5 d-flex flex-column align-items-lg-start align-items-center"
            >
              <Link to="/">
                <img
                  src="/images/logo-footer.svg"
                  width="130"
                  alt="logo"
                  className="mb-4"
                />
              </Link>
              <small className="disclaimer pb-4 d-none d-lg-block">
                {intl.formatMessage({ id: 'footer.disclaimer' })}
              </small>
              <SelectLanguage
                className="basic-single_footer"
              />
            </Col>
            <Col
              lg={3}
              md={6}
              xs={12}
              className="mb-lg-0 mb-5 d-flex flex-column align-items-lg-start align-items-center"
            >
              <div className="subfooter-title">{intl.formatMessage({ id: 'footer.usefulResources' })}</div>
              <a
                href="https://apps.apple.com/app/id1526637882"
                className="subfooter-link"
                target="_blank"
                rel="noreferrer"
              >
                App Store
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=md.mediacritica.mediaradar"
                className="subfooter-link"
                target="_blank"
                rel="noreferrer"
              >
                Play Store
              </a>
            </Col>
            <Col
              lg={3}
              md={6}
              xs={12}
              className="mb-lg-0 mb-5 d-flex flex-column align-items-lg-start align-items-center"
            >
              <div className="subfooter-title">Media RADAR</div>
              <Link to="/media-agencies/" className="subfooter-link">
                {intl.formatMessage({ id: 'navbar.institutions' })}
              </Link>
              <Link to="/metodologia/" className="subfooter-link">
                {intl.formatMessage({ id: 'navbar.metodologia' })}

              </Link>
              <Link to="/about/" className="subfooter-link">
                {intl.formatMessage({ id: 'navbar.about' })}

              </Link>
              <Link to="/contact/" className="subfooter-link">
                {intl.formatMessage({ id: 'navbar.contact' })}
              </Link>
            </Col>
            <Col
              lg={3}
              md={6}
              xs={12}
              className="d-flex flex-column align-items-lg-start align-items-center"
            >
              <div className="subfooter-title">{intl.formatMessage({ id: 'footer.subscribe' })}</div>
              <FooterForm />
              <Row noGutters className="mt-4">
                <div className="social-wrap">
                  <FontAwesome className="social-icon" name="facebook" />
                  <a
                    href="https://www.facebook.com/Mediacritica.md"
                    target="_blank"
                    rel="noreferrer"
                    className="link-block social-link"
                  ></a>
                </div>
                <div className="social-wrap">
                  <FontAwesome className="social-icon" name="instagram" />
                  <a
                    href="https://www.instagram.com/cjimoldova"
                    target="_blank"
                    rel="noreferrer"
                    className="link-block social-link"
                  ></a>
                </div>
                <div className="social-wrap">
                  <FontAwesome className="social-icon" name="linkedin" />
                  <a
                    href="https://www.linkedin.com/company/cji-moldova/"
                    target="_blank"
                    rel="noreferrer"
                    className="link-block social-link"
                  ></a>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col lg={3} xs={12} className="order-lg-1 order-4">
              <small className="disclaimer pb-5 d-block d-lg-none">
                {intl.formatMessage({ id: 'footer.disclaimer' })}
              </small>
              <div className="subfooter-link text-center text-lg-left">
                Copyright © {new Date().getFullYear()} Media RADAR
              </div>
            </Col>
            <Col lg={3} xs={12} className="order-2" />
            <Col lg={3} xs={12} className="order-3" />
            <Col lg={3} xs={12} className="order-lg-4 order-1 mb-lg-0 mb-4">
              <Row className="d-flex justify-content-between" noGutters>
                <Link to="/privacy/" className="subfooter-link">
                  {intl.formatMessage({ id: 'footer.confidentiality' })}
                </Link>
                <Link to="/tos/" className="subfooter-link">
                  {intl.formatMessage({ id: 'footer.tac' })}
                </Link>
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    </footer>
  )
}

export default Footer